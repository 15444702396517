import {IGenericProps} from "../interfaces/generics";
import React from "react";
import {AppContext} from "../components/AppContext";
import api from "../requests/api";
import {IContext} from "../interfaces/context";
import {getNextRoute} from "../util/RouteHelper";
import { Branch, Company, Schedule } from "../interfaces/models";

interface Props extends IGenericProps {

}

const getCompany = async (props: Props) => {
    const {companyName} = props.match.params;
    console.debug("Fetching company");
    return (await api.companies().get(companyName)).data as Company;
};

const getSchedule = async (props: Props, company: Company, branch?: Branch) => {
    const queryParams = new URLSearchParams(props.location.search);
    const schedName = props.match.params.schedule || queryParams.get("schedule") || undefined;
    if(!schedName) return {};

    console.debug("Fetching scheudle");
    const allSchedules: Schedule[] = (await api.schedules().getAll(company.id)).data;
    let matchingSchedules = allSchedules.filter(s => s.name === schedName);
    if(branch && branch.id){
        matchingSchedules = matchingSchedules.filter(s => s.branches.some(b => b.id===branch.id));
    }
    const selectedScheduleName = schedName;
    const selectedSchedule = matchingSchedules.length === 1 ? matchingSchedules[0] : undefined;
    return {
        selectedScheduleName,
        selectedSchedule
    };
};

const getBranch = async (props: Props, company: Company) => {
    const queryParams = new URLSearchParams(props.location.search);
    const branchName = props.match.params.branch || queryParams.get("branch");
    if(!branchName) return;

    console.debug("Fetching branch");
    const branches: Branch[] = (await api.branches().getAll(company.id)).data;
    return branches.find(s => s.name === branchName)
};

const getDateTime = (props: Props) => {
    const dateStr = props.match.params.date;
    if(!dateStr) return;
    return new Date(dateStr);
};

const fetchDataAndRedirect = async (ctx: IContext, props: Props) => {
    let {company} = ctx.state;

    if (!(company && company.id) || company.name !== props.match.params.companyName) {
        company = await getCompany(props);
    }
    const selectedBranch = await getBranch(props, company);
    console.debug('selectedBranch', selectedBranch);
    const {selectedSchedule, selectedScheduleName} = await getSchedule(props, company, selectedBranch);
    console.debug('selectedSchedule', selectedScheduleName, selectedSchedule);
    const dateTime = getDateTime(props);

    const newState = await ctx.actions.updateState({
        company,
        selectedSchedule,
        selectedScheduleName,
        selectedBranch,
        dateTime,
        preventReset: {
            schedule: !!selectedScheduleName,
            branch: !!selectedBranch,
            dateTime: !!dateTime
        }
    });

    const newCtx = { ...ctx, state:newState }
    props.history.push(getNextRoute(newCtx));
};


class Redirector extends React.Component<Props, void>{
    private retries: number;

    constructor(props: Props) {
        super(props);
        this.retries = 0;
    }

    componentDidMount() {
        fetchDataAndRedirect(this.context, this.props)
            .catch(err => {
                console.error("Error al redirigir", err);
                if (this.retries < 3){
                    console.debug("Retrying redirection");
                    setTimeout(()=>{
                        this.retries++;
                        this.componentDidMount();
                    }, 200);
                } else {
                    console.log("Redirecting to /");
                    this.props.history.push('/');
                }
            });
    }

    render(){
        return <></>;
    }

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>;
}

export default Redirector;
