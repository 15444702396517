import React, { ButtonHTMLAttributes } from "react";
import { Events } from "../interfaces/events";
import * as Analytics from "../util/Analytics";

interface TrackedButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "id">{
    extraData?: Record<string, unknown>;
    id?: Events;
}

const TrackedButton = (props: TrackedButtonProps) => {
    const click = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.id) Analytics.event(props.id, props.extraData);
        if (props.onClick) return props.onClick(ev);
    };
    return <button {...props} onClick={click} />;
};

export default TrackedButton;
