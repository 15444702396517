import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const lng = "es", fallbackLng= 'es';
const localStorageKey = "translation-";

let cbTranslationsLoaded: ()=>unknown;

const getLang = async (lang: string) => {
    try {
        const localTranslations = localStorage.getItem(localStorageKey + lang);
        if(localTranslations){
            i18n.addResourceBundle(lang, 'translation', JSON.parse(localTranslations))
        }
        const res = await fetch(process.env.PUBLIC_URL + "/static/lang/" + lang + ".json");
        if (!res.ok)
            throw new Error(res.status + ' ' + res.statusText);
        const json = await res.json();
        i18n.addResourceBundle(lang, 'translation', json);
        localStorage.setItem(localStorageKey + lang, JSON.stringify(json));
        if (cbTranslationsLoaded)
            cbTranslationsLoaded();
    } catch (e) {
        console.error("Error buscando idioma '" + lang + "'.", e);
    }
}

i18n.use(initReactI18next)
    .init({
        resources:{},
        lng,
        fallbackLng,

        keySeparator: '.',
        interpolation: {
            escapeValue: false
        }
    });

getLang(lng).finally(() => {
    if(fallbackLng !== lng) getLang(fallbackLng)
});

const i18nEvents = {
    onTranslationsLoaded: (cb: () => unknown) => {
        cbTranslationsLoaded = cb;
    }
};

export default i18n;
export {i18nEvents};

