import dayjs from "dayjs";
import dayjs_locales from "dayjs/locale.json";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "./locale/imports";

const DEFAULT_LOCALE = 'es'

dayjs.extend(localizedFormat)

const nav_langs = navigator.languages || [navigator.language || DEFAULT_LOCALE];

const findLocale = (lang: string): string | undefined => {
    // Adjust some locales to fit client's needs
    if (lang === 'es' || lang === 'es-mx')
        return 'es-us';
    if (lang === 'es-ar')
        return 'es';

    if (dayjs_locales.some(locale => locale.key === lang))
        return lang;
    if (lang.includes('-'))
        return findLocale(lang.split('-')[0]);
    return undefined;
}

const autodetect = () => {
    // Find, in order of priority, the first user locale that can be used
    const locale = nav_langs
        .map((lang) => lang.toLowerCase())
        .map((lang) => findLocale(lang))
        .find((lang) => lang !== undefined)
            || DEFAULT_LOCALE;

    return locale;
}

export const setAutodetectedLocale = () => {
    dayjs.locale(autodetect());
}

