import axios from 'axios';
import React from 'react';
import { Translation } from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {ConfirmationType, EModality, IContext, IContextState} from "../interfaces/context";
import { IGenericProps } from '../interfaces/generics';
import {Branch, Company, Customer, CustomerFields, Schedule} from '../interfaces/models';


export const AppContext = React.createContext<IContext>({state: {}, actions: {}, t: ()=>''});
const stateKey = 'debQAppointments';

export const AppConsumer = AppContext.Consumer;

class Context extends React.Component<Omit<IGenericProps, "ctx">, IContextState> {
    constructor(props: Omit<IGenericProps, "ctx">) {
        super(props);
        const state = JSON.parse(
            sessionStorage.getItem(stateKey) ||
            JSON.stringify({
                company: undefined,
                selectedSchedule: undefined,
                selectedScheduleName: undefined,
                selectedBranch: undefined,
                dateTime: undefined,
                customer: undefined,
                modality: undefined,
                confirmationType: undefined,
                reason: undefined,
                randomFileId: undefined
            } as IContextState)
        ) as IContextState;

        state.dateTime = state.dateTime ? new Date(state.dateTime) : undefined;

        this.state = state;

        this.updateState = this.updateState.bind(this);
        this.clear = this.clear.bind(this);
        this.getBackgroundUrl = this.getBackgroundUrl.bind(this);
        this.getCustomCss = this.getCustomCss.bind(this);
        this.selectCompany = this.selectCompany.bind(this);
        this.selectSchedule = this.selectSchedule.bind(this);
        this.selectScheduleName = this.selectScheduleName.bind(this);
        this.selectBranch = this.selectBranch.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.setObligatoryFields = this.setObligatoryFields.bind(this);
        this.selectModality = this.selectModality.bind(this);
        this.setConfirmationType = this.setConfirmationType.bind(this);
        this.setReason = this.setReason.bind(this);
        this.setRandomFileId = this.setRandomFileId.bind(this);
        this.getLogoUrl = this.getLogoUrl.bind(this);
    }

    customBgBlobUrl?: string;
    customCss?: string;

    /**
     * Set a state and save it in the session storage
     * @param newState
     * @param callback
     */
    updateState(newState: IContextState, callback?: (arg: IContextState) => void) {
        return new Promise((res) => {
            this.setState(newState, () => {
                sessionStorage.setItem(stateKey, JSON.stringify(this.state));
                if(newState.company) {
                    this.customBgBlobUrl = undefined;
                    document.dispatchEvent(new Event('companySelected'))
                }
                res(this.state);
                if (callback)
                    callback(this.state);
            });
        });
    }

    clear = ()=>{
        return this.updateState({
            company: undefined,
            selectedSchedule: undefined,
            selectedScheduleName: undefined,
            modality: undefined,
            selectedBranch: undefined,
            dateTime: undefined,
            customer: undefined,
            confirmationType: undefined,
            reason: undefined,
            randomFileId: undefined
        });
    }

    async getBackgroundUrl(){
        if(this.customBgBlobUrl) return this.customBgBlobUrl;
        if(this.state.company?.backgroundUrl){
            const res = await axios.get(this.state.company.backgroundUrl, {
                responseType: "blob"
            });
            return this.customBgBlobUrl = URL.createObjectURL(res.data);
        }
        return (process.env.NODE_ENV === "development" ? "/mockData" : "") + "/assets/debqappointments/images/background.png";
    }

    async getCustomCss(){
        if(this.customCss) return this.customCss;
        if(this.state.company?.cssUrl){
            const res = await axios.get(this.state.company.cssUrl);
            return this.customCss = res.data;
        }
        return '';
    }

    async getLogoUrl(){
        if(this.state.company?.logoUrl) return this.state.company?.logoUrl;
        return (process.env.NODE_ENV === "development" ? "/mockData" : "") + "/assets/debqappointments/images/logo-debq.png";
    }

    /**
     * Change the selected company state
     * @param selected
     * @param event
     */
    selectCompany(selected: Company): Promise<void> {
        return new Promise((res) => {
            this.updateState({company: selected}, () => {
                this.customBgBlobUrl = undefined;
                this.customCss = undefined;
                document.dispatchEvent(new Event('companySelected'))
                res();
            });
        });
    }

    selectSchedule(selected: Schedule): Promise<void> {
        return new Promise((res) => {
            this.updateState({selectedSchedule: selected}, () => res());
        });
    }

    selectScheduleName(selected: string): Promise<void> {
        return new Promise((res) => {
            this.updateState({selectedScheduleName: selected}, () => res());
        });
    }

    selectBranch(selected: Branch): Promise<void> {
        return new Promise((res) => {
            this.updateState({selectedBranch: selected}, () => res());
        });
    }

    selectDate(selected?: Date): Promise<void> {
        return new Promise((res) => {
            this.updateState({dateTime: selected}, () => res());
        });
    }

    selectCustomer(selected: Customer): Promise<void> {
        return new Promise((res) => {
            this.updateState({customer: selected}, () => res());
        });
    }

    setObligatoryFields(selected: CustomerFields): Promise<void> {
        return new Promise((res) => {
            this.updateState({obligatoryFields: selected}, () => res());
        });
    }

    selectModality(selected: EModality): Promise<void> {
        return new Promise((res) => {
            this.updateState({modality: selected}, () => res());
        });
    }

    setConfirmationType(selected?: ConfirmationType): Promise<void> {
        return new Promise((res) => {
            this.updateState({confirmationType: selected}, () => res());
        });
    }

    setRandomFileId(selected?: string): Promise<void> {
        return new Promise((res) => {
            this.updateState({randomFileId: selected}, () => res());
        });
    }

    setReason(selected?: string): Promise<void> {
        return new Promise((res) => {
            this.updateState({reason: selected}, () => res());
        });
    }

    render() {

        return (
            <Translation>
                {
                    (t) =>
                        <AppContext.Provider
                            value={{
                                state: this.state,
                                actions: {
                                    getBackgroundUrl: this.getBackgroundUrl,
                                    getCustomCss: this.getCustomCss,
                                    selectCompany: this.selectCompany,
                                    selectSchedule: this.selectSchedule,
                                    selectScheduleName: this.selectScheduleName,
                                    selectBranch: this.selectBranch,
                                    selectDate: this.selectDate,
                                    selectCustomer: this.selectCustomer,
                                    updateState: this.updateState,
                                    setObligatoryFields: this.setObligatoryFields,
                                    selectModality: this.selectModality,
                                    setConfirmationType: this.setConfirmationType,
                                    setRandomFileId : this.setRandomFileId,
                                    setReason: this.setReason,
                                    getLogoUrl: this.getLogoUrl,
                                    clear: this.clear
                                },
                                t: t
                            } as IContext}>
                            {this.props.children}
                        </AppContext.Provider>
                }
            </Translation>
        );
    }
}

export const AppProvider = withRouter(Context);
