import NodeRSA from "node-rsa"
import { Customer } from "../interfaces/models";

export const encrypt = (toEncrypt: string, publicKeyStr: string) => {
    var key = new NodeRSA();
    key.setOptions({
        encryptionScheme: "pkcs1"
    });
    key.importKey(publicKeyStr, "pkcs8-public");

    return key.encrypt(toEncrypt, "base64");
}

const fieldsToEncrypt: (keyof Customer)[] = [
    "dni",
    "email",
    "firstName",
    "lastName",
    "customerNumber",
    "cuit",
    "cuil",
    "businessName",
    "phone",
    "passport",
    "libretaCivica",
    "libretaEnrolamiento",
    "cedulaDiplomatica",
    "cedulaIdentidad"
];

export const encryptCustomer = (customer: Customer, publicKey: string) => {
    const encryptedCustomer = { ...customer };
    for (const field of fieldsToEncrypt) {
        if(typeof customer[field] === "string") {
            encryptedCustomer[field] = encrypt(customer[field] as string, publicKey);
        }
    }
    return encryptedCustomer;
};


