import { TFunction } from "i18next";
import {Branch, Company, Customer, CustomerFields, Schedule} from "./models";

export enum EModality {
    branchesFirst,
    dateFirst
}

export type ConfirmationType = "EMAIL" | "SMS" | "NONE";

export interface IContextState {
    company?: Company,
    selectedSchedule?: Schedule,
    selectedScheduleName?: string,
    selectedBranch?: Branch,
    dateTime?: Date,
    customer?: Customer,
    obligatoryFields?: CustomerFields
    modality?: EModality,
    confirmationType?: ConfirmationType,
    reason?: string
    randomFileId?: string,
    preventReset?: {
        schedule: boolean,
        branch: boolean,
        dateTime: boolean,
    },
}

export type IContextAction = (...args: (any)[]) => void | Promise<any>;

export interface IContext {
    state: IContextState,
    actions: {
        [actionName: string]: IContextAction
    },
    t: TFunction | (() => string)
}
