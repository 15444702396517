import React from "react";
import { AppConsumer, AppContext } from "../components/AppContext";
import { RouteComponentProps } from "react-router";
import { IGenericProps } from "../interfaces/generics";
import { getNextRoute } from "../util/RouteHelper";
import { Company, Customer, CustomerFields, CustomerFieldsLabels, CustomerMaxLength } from "../interfaces/models";
import api from "../requests/api";
import { toast } from "react-toastify";
import { getTranslations, validateCustomer, ValidationError, EValidationErr, EPropTypes } from "../util/CustomerUtils";
import { toSnakeCase } from "../util/StringUtils";
import Spinner from "../components/Spinner";
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";

interface IClientFormState {
    visibleFields?: CustomerFields,
    obligatoryFields?: CustomerFields,
    dirty: CustomerFields & { reason?: boolean },
    fieldLabels?: CustomerFieldsLabels,
    maxLength: CustomerMaxLength,
    customer: Customer,
    reason?: string,
    fileUploading?: boolean,
    fileName?: string
}

class ClientForm extends React.Component<RouteComponentProps & IGenericProps, IClientFormState> {
    constructor(props: RouteComponentProps & IGenericProps) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.next = this.next.bind(this);
        this.state = {
            customer: {},
            maxLength: {},
            dirty: {},
            reason: '',
            fileUploading: false,
            fileName: ''
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    next() {
        this.props.history.push(getNextRoute(this.context));
        // TODO pegarle a una API para validar antes de pasar a la siguiente vista
        // (por las dudas, por si las validaciones de front y de back no coinciden)
    }

    async componentDidMount() {
        const { t } = this.context;
        const ctxCompany = this.context.state.company;
        const { selectedSchedule } = this.context.state;
        if (!selectedSchedule || !ctxCompany || ctxCompany.name !== this.props.match.params.companyName) {
            console.error("Redirecting since no schedule or company was selected in form");
            toast.error(t("ClientForm.TOAST_NOT_SELECTED", {
                what: !this.context.state.company ? t("ClientForm.TOAST_COMPANY") : t("ClientForm.TOAST_SCHEDULE")
            }));
            this.props.history.push(`/company/${this.props.match.params.companyName || ''}`);
            return;
        }

        document.getElementById('fileInput')?.addEventListener("change", ev => this.fileChanged(ev));

        const [{ data: visibleFields },
            { data: obligatoryFields },
            { data: fieldLabels },
            { data: maxLength }] = (await Promise.all([
                api.customerAttributes().getVisible(selectedSchedule),
                api.customerAttributes().getObligatory(selectedSchedule),
                api.customerAttributes().getFields(this.context.state.company as Company),
                api.customerAttributes().getMaxLength(this.context.state.company as Company),
            ])) as [{ data: CustomerFields }, { data: CustomerFields }, { data: CustomerFieldsLabels }, { data: CustomerMaxLength }]

        visibleFields.firstName = obligatoryFields.firstName = true;
        visibleFields.lastName = obligatoryFields.lastName = true;
        this.context.actions.setObligatoryFields(obligatoryFields);

        const customer = { ...this.context.state.customer };
        const reason = this.context.state.reason || '';

        this.setState({
            visibleFields,
            obligatoryFields,
            fieldLabels,
            maxLength,
            customer,
            reason
        });

    }

    componentDidUpdate() {
        const ctxCompany = this.context.state.company;
        if (ctxCompany?.name !== this.props.match.params.companyName) {
            console.warn("Company changed, redirecting");
            this.props.history.push(`/company/${this.props.match.params.companyName || ''}`);
            return;
        }
    }

    fileChanged(ev: Event) {
        const MB = 1 * 1024 * 1024;
        const target = ev.target as HTMLInputElement;
        const file = target.files?.[0];
        const {t} = this.context;
        if (!file) return;
        if (target.value.length === 0) {
            // El usuario tocó cancelar
            return;
        }
        if (file.size > 2 * MB) {
            target.value = '';
            toast.error(t("ClientForm.TOAST_OVERSIZED_FILE"));
            return;
        }
        this.uploadFile(file, target);
    }

    async uploadFile(file: File, target: HTMLInputElement) {
        this.setState({ fileUploading: true });

        const formData = new FormData();
        formData.append("file", file);

        let res;
        const {t} = this.context;
        try {
            res = await api.appointment().uploadFile(formData, this.context.state.company?.name as string);
        } catch (e) {
            console.error("Error uploading file", e);
            this.setState({ fileUploading: false });
            toast.error(t("ClientForm.TOAST_FAILED_TO_UPLOAD_FILE"));
            target.value = '';
            return;
        }

        this.setState({ fileName: file.name });

        this.context.actions.setRandomFileId(res.data.randomId);

        toast.success(t("ClientForm.TOAST_SUCCEEDED_TO_UPLOAD_FILE"));
        this.setState({ fileUploading: false });
    }

    getAutocompleteOpt = (prop: keyof Customer) => {
        const opts : {[key in keyof Customer]: string}= {
            phone: 'tel-local',
            email: 'email',
            firstName: 'given-name',
            lastName: 'family-name'
        }
        return opts[prop] || 'on';
    }

    propsChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = ev.target;
        let { customer, dirty, reason } = this.state
        dirty[name as (keyof Customer | 'reason')] = true;
        if (name !== 'reason')
            customer[name as keyof Customer] = value;
        else
            reason = value

        this.setState({
            customer,
            reason,
            dirty
        });
        this.context.actions.selectCustomer(customer);
        this.context.actions.setReason(reason);
    }

    getErrorText = (errors: ValidationError[], field: keyof Customer) => {
        const {t} = this.context;
        if (!this.state.dirty[field]) return '';
        const err = errors.find(err => err.target === field);
        if (!err)
            return '';
        if (err.key === EValidationErr.missing_field)
            return t("ClientForm.REQUIRED");
        switch(err?.dataType) {
        case EPropTypes.number:
            return t("ClientForm.INVALID_NUMBER");
        case EPropTypes.text:
            return t("ClientForm.INVALID_TEXT");
        case EPropTypes.email:
            return t("ClientForm.INVALID_EMAIL");
        case EPropTypes.alphanumeric:
            return t("ClientForm.INVALID_ALPHANUMERIC");
        default:
            return t("ClientForm.INVALID");
        }
    }

    getReasonErrorText = (reason?: string) => {
        const {t} = this.context;
        if (!this.context.state.selectedSchedule?.reasonRequired) return '';
        if (!this.state.dirty['reason']) return '';
        return !reason ? t("ClientForm.REQUIRED") : '';
    }

    fileHasError = () => {
        const { selectedSchedule } = this.context.state;
        return this.state.fileUploading || (selectedSchedule?.fileRequired && !this.state.fileName);
    }

    render() {

        if (!this.context.state.selectedSchedule) return <></>;

        const { visibleFields, obligatoryFields, fieldLabels, customer, reason, fileUploading, fileName, maxLength } = this.state;
        const { selectedSchedule } = this.context.state;
        const { t } = this.context;

        const fields = getTranslations(visibleFields, fieldLabels, t);
        const errors = customer ? validateCustomer(customer, obligatoryFields, visibleFields) : [];

        const { enableReason, reasonRequired } = selectedSchedule;
        const reasonError = this.getReasonErrorText(reason);

        return (
            <AppConsumer>
                {({t}) => (
                    <>
                        <div className="form-section form-card__content">
                            <form className="form-section__form">
                                {
                                    fields.map(field => {
                                        const error = this.getErrorText(errors, field.key);
                                        return (
                                            <div className={'form-section__input-container'} key={field.key}>
                                                <input type="text" className={`form-section__form-input form-section__form-input${error ? '-error' : ''}`}
                                                    onChange={this.propsChanged}
                                                    name={field.key}
                                                    maxLength={maxLength[toSnakeCase(field.key)]}
                                                    autoComplete={this.getAutocompleteOpt(field.key)}
                                                    value={customer[field.key] || ''}/>
                                                <span className={`${!!customer[field.key] ? 'form-section__floating-label-dirty' : ''} form-section__floating-label`}>
                                                    {field.showName + (obligatoryFields?.[field.key] ? '*' : '')}
                                                </span>
                                                <div className="form-section__text-error">
                                                    {error}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    enableReason &&
                                    <div className={'form-section__input-container'}>
                                        <input type="text" className={`form-section__form-input form-section__form-input${reasonError ? '-error' : ''}`}
                                            onChange={this.propsChanged}
                                            name={"reason"}
                                            autoComplete="off"
                                            value={reason}/>
                                        <span className={`${!!reason ? 'form-section__floating-label-dirty' : ''} form-section__floating-label`}>
                                            { t("ClientForm.APPOINTMENT_REASON") + (reasonRequired ? '*' : '')}
                                        </span>
                                        <div className="form-section__text-error">
                                            {reasonError}
                                        </div>
                                    </div>
                                }
                                {
                                    selectedSchedule.allowFileUpload && <>
                                        <div className={'form-section__file-container'}>
                                            <label htmlFor="fileInput" className="form-section__form-fileupload text-center">
                                                <i className="bi bi-file-earmark-arrow-up"></i>  {(fileName || selectedSchedule.fileUploadLabel || t("ClientForm.ATTACH_FILE")) + (selectedSchedule.fileRequired ? "*" : "")}
                                                <Spinner loading={fileUploading} color="#DCDCDC" scale={.4} style={{
                                                    marginLeft: "auto"
                                                }} />
                                            </label>
                                            <input id="fileInput" style={{ visibility: "hidden" }} type="file"></input>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                        <div className="list-selection__buttons">
                            <TrackedButton
                                id="BtnBackScheduleFirst"
                                className="list-selection__button list-selection__button-green form-card__button form-card__button--previous"
                                onClick={this.goBack}> {t("ClientForm.PREVIOUS")}
                            </TrackedButton>
                            <TrackedButton
                                id="next_clientform"
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--next"
                                onClick={this.next}
                                disabled={!customer || errors.length > 0 || (reasonRequired && !reason) || this.fileHasError()}>{t("ClientForm.NEXT")}
                            </TrackedButton>
                        </div>
                    </>
                )}
            </AppConsumer>
        )
    }

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>;
}

export default withTracker(ClientForm);