import React, {Component} from 'react';
import {
    Switch,
    Redirect,
    withRouter,
    RouteComponentProps
} from 'react-router-dom'
import {AppConsumer} from "./components/AppContext";
import RouteCatchError from "./components/ErrorBoundary/RouteCatchError";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import SelectSchedule from "./views/SelectSchedule";
import SelectModality from "./views/SelectModality";
import SelectBranch from "./views/SelectBranch";
import CalendarView from "./views/CalendarView";
import ClientForm from "./views/ClientForm";
import ConfirmView from "./views/ConfirmView";
import Steps from "./components/Steps"
import {QueryParamsType} from './interfaces/generics';
import {IContext} from "./interfaces/context";
import Redirector from "./views/Redirector";
import SelectCompany from './views/SelectCompany';
import {i18nEvents} from './util/i18n'
import * as Analytics from './util/Analytics';
import { Flip, ToastContainer } from 'react-toastify';
import Reserved from './views/Reserved';
import NotFound from './views/NotFound';

import './styles/main.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import ViewLoader from './components/ViewLoader';


interface AppState {
    loading: boolean;
    logoUrl?: string;
    customCss?: string;
}

interface IAppProps {
    ctx: IContext;
}


class App extends Component<IAppProps, AppState, RouteComponentProps> {

    constructor(props: IAppProps & RouteComponentProps) {
        super(props);
        this.state = {
            loading: true
        };
        i18nEvents.onTranslationsLoaded(()=> this.forceUpdate());
    }


    async updateBackground(){
        const bgUrl = await this.props.ctx.actions.getBackgroundUrl();
        console.debug("Updating background:", bgUrl)
        document.body.style.backgroundImage = `url('${bgUrl}')`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
    }

    async updateCss(){
        let customCss = await this.props.ctx.actions.getCustomCss();
        customCss = customCss.replace(/(!important)?\s*;+/ig, "!important;")
        await new Promise<void>((res) =>
            this.setState({customCss}, res));
    }

    gtagLoaded = false;
    initGTAG() {
        if (this.gtagLoaded) return;
        if(this.props.ctx.state.company?.hasGoogleAnalytics) {
            this.gtagLoaded = true;
            Analytics.initialize(this.props.ctx.state.company.googleAnalyticsId);
        } else {
            Analytics.deinitialize();
        }
    }

    async updateLogo(){
        const logoUrl: string = await this.props.ctx.actions.getLogoUrl();
        this.setState({
            logoUrl
        });
    }

    async updateResources(){
        const { company } = this.props.ctx.state;
        const { pathname, hash } = window.location;
        const isPreCompanySelection = ['companies', '404'].some(
            route => pathname.includes(route) || hash.includes(route))
        if ((!company || !company.id) && !isPreCompanySelection)
            return;
        try{
            await Promise.all([
                this.updateCss(),
                this.updateBackground(),
                this.updateLogo(),
            ]);
        } finally {
            this.setState({loading: false});
        }
    }

    async componentDidMount() {
        await this.updateResources();
        this.initGTAG();
        document.addEventListener('companySelected', () => {
            this.updateResources();
            this.initGTAG();
        });
    }


    render() {
        const {ctx} = this.props;
        const { logoUrl, loading } = this.state;
        document.title = ctx.t("Index.TITLE");

        // TODO investigate codesplitting/preloading when we have time
        // https://mono.software/2019/12/30/code-splitting-and-preloading-react-routes-with-webpack/
        // https://stackoverflow.com/a/58509569
        return (
            <ViewLoader loading={loading} fullscreen={false}>
                <ErrorBoundary>
                    <style>
                        {/* Custom Styles */}
                        {
                            this.state.customCss
                        }
                    </style>

                    <div className={`${ctx.state.company?.retroStyle ? 'retro' : ''} main-container`}>
                        <div className={`${ctx.state.company?.retroStyle ? 'retroLogo' : 'hideLogo'}`}>
                            <img src={logoUrl} className="content-box__logo-img" alt="Logo"/>
                        </div>
                        <Steps/>
                        <div className="content-box form-card">

                            <Switch>
                                {/* All old format URLs will go through the redirector, for compatibility reasons */}
                                <RouteCatchError exact path={[
                                    "/company/:companyName",
                                    "/company/:companyName/getAppointments",
                                    "/company/:companyName/branchesView/:branch/scheduleView",
                                    "/company/:companyName/branchesView",
                                    "/company/:companyName/schedules",
                                    "/company/:companyName/scheduleView",
                                    "/company/:companyName/schedules/:schedule/branches",
                                    "/company/:companyName/scheduleView/:schedule/branchesView",
                                    "/company/:companyName/schedules/:schedule/branches/:branch/days",
                                    "/company/:companyName/branchesView/:branch/scheduleView/:schedule/daysView",
                                    "/company/:companyName/scheduleView/:schedule/branchesView/:branch/daysView",
                                    "/company/:companyName/schedules/:schedule/branches/:branch/days/:date/customerInfo",
                                    "/company/:companyName/branchesView/:branch/scheduleView/:schedule/daysView/:date/customerInfoView",
                                    "/company/:companyName/scheduleView/:schedule/branchesView/:branch/daysView/:date/customerInfoView"]}
                                component={Redirector}/>
                                <RouteCatchError exact path="/company/:companyName/schedule" component={SelectSchedule} />
                                <RouteCatchError exact path="/company/:companyName/modality" component={SelectModality} />
                                <RouteCatchError exact path="/company/:companyName/branches" component={SelectBranch} />
                                <RouteCatchError exact path="/company/:companyName/date" component={CalendarView} />
                                <RouteCatchError exact path="/company/:companyName/form" component={ClientForm} />
                                <RouteCatchError exact path="/company/:companyName/confirm" component={ConfirmView} />
                                <RouteCatchError exact path="/company/:companyName/done" component={Reserved} />
                                <RouteCatchError exact path="/companies" component={SelectCompany} />
                                <RouteCatchError exact path="/404" component={NotFound} />
                                <Redirect from='*' to='/404' />
                            </Switch>
                        </div>
                    </div>

                    {/*These options can be changed on the toast function call*/}
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        transition={Flip}
                        theme={"colored"}
                    />
                </ErrorBoundary>
            </ViewLoader>
        );
    }
}

/**
 * For access to the method and values of the context
 */
const AppRef = React.forwardRef<App>((props, ref) => (
    <AppConsumer>
        {(ctx: IContext) => <App {...props} ctx={ctx} ref={ref}/>}
    </AppConsumer>
));

type TAppRefProps = Omit<IAppProps, "ctx">;
/**
 * For access to the history
 */
export default withRouter<RouteComponentProps<QueryParamsType> & TAppRefProps, any>(AppRef);
