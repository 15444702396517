import React, {ChangeEvent} from "react";
import {AppConsumer, AppContext} from "../components/AppContext";
import {RouteComponentProps} from "react-router";
import {IGenericProps} from "../interfaces/generics";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import api from "../requests/api";
import {
    Appointment,
    Branch,
    Company,
    CustomerFields,
    CustomerFieldsLabels,
    Schedule,
    TermsAndConditions
} from "../interfaces/models";
import {getTranslations} from "../util/CustomerUtils";
import {getNextRoute} from "../util/RouteHelper";
import {ConfirmationType} from "../interfaces/context";
import ReCAPTCHA from "react-google-recaptcha";
import ModalDialog, {EModalTypes} from "../components/ModalDialog";
import {encryptCustomer} from "../util/EncryptionUtils";
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import {setAutodetectedLocale} from "../util/DayjsLocaleResolver";
import axios, {AxiosError} from "axios";

setAutodetectedLocale()

interface IConfirmViewState {
    fieldLabels?: CustomerFieldsLabels;
    captchaToken?: string;
    diableButton: boolean;
    termsAndConditions?: TermsAndConditions;
    showTyc: boolean;
    termsAccepted: boolean;
}

class ConfirmView extends React.Component<RouteComponentProps & IGenericProps, IConfirmViewState> {

    readonly fieldsToShow: (keyof CustomerFields)[] = ['firstName', 'lastName', 'email', 'dni', 'phone']

    readonly sitekey = '6Ldt0gAVAAAAAGVHDBF3kvUy2I2IkltzgjNENxdY'

    recaptchaInstance: ReCAPTCHA | null = null;

    pubkey: string = '';

    constructor(props: RouteComponentProps & IGenericProps) {
        super(props);
        this.goToForm = this.goToForm.bind(this);
        this.goBack = this.goBack.bind(this);
        this.editData = this.editData.bind(this);
        this.state = {
            diableButton: false,
            showTyc: false,
            termsAccepted: false
        }
    }

    editData(data: string) {
        const ctxCompany = this.context.state.company as Company;
        switch (data) {
            case "dateOrTime":
                this.props.history.push('/company/' + ctxCompany.name + '/date');
                break;
            case "branch":
                this.props.history.push('/company/' + ctxCompany.name + '/branches');
                break;
            case "schedule":
                this.props.history.push('/company/' + ctxCompany.name + '/schedules');
                break;
        }
    };

    goToForm() {
        this.props.history.push('/formulario');
    }

    goBack() {
        this.props.history.goBack();
    }

    async next() {
        const {selectedBranch, selectedSchedule, customer, dateTime} = this.context.state;
        const {termsAccepted} = this.state;
        const {t} = this.context;

        this.setState({diableButton: true});

        const appointment: Appointment = {
            branch: selectedBranch as Branch,
            schedule: selectedSchedule as Schedule,
            customer: encryptCustomer(customer || {}, this.pubkey),
            startAt: dayjs(dateTime).format('YYYY-MM-DDTHH:mm:ssZZ'),
            extraFields: {},
            reason: this.context.state.reason,
            file: {
                randomId: this.context.state.randomFileId as string
            },
            captcha: {
                token: this.state.captchaToken || ''
            },
            acceptedTerms: termsAccepted
        };

        let res;
        try {
            res = await api.appointment().confirm(appointment);
        } catch (e) {
            console.error("Error confirming appointment", e);
            this.setState({diableButton: false, captchaToken: ''});
            this.recaptchaInstance?.reset();
            if (axios.isAxiosError(e)) {
                toast.error(t(e.response?.data?.message ||
                    "ConfirmView.TOAST_APPOINTMENT_CONFIRMATION_ERROR"));
            } else {
                toast.error(t("ConfirmView.TOAST_APPOINTMENT_CONFIRMATION_ERROR"));
            }

            return;
        }

        this.context.actions.setConfirmationType(res.data?.confirmationType as ConfirmationType);

        this.props.history.push(getNextRoute(this.context));
    }

    async componentDidMount() {
        const {t} = this.context;
        const {selectedSchedule, selectedBranch, company} = this.context.state;
        if (!selectedSchedule || !selectedBranch || !company) {
            console.error("No branch or schedule was selected in CalendarView");
            toast.error(`${t("ConfirmView.TOAST_NOT_SELECTED")} ${!selectedBranch ? t("ConfirmView.COMPANY") : t("ConfirmView.SCHEDULE")}`);
            this.props.history.push(`/company/${this.props.match.params.companyName || ''}`);
            return;
        }
        const [
            {data: fieldLabels},
            {data: termsAndConditions},
            {data: pubkey},
        ] = (await Promise.all([
            api.customerAttributes().getFields(company),
            selectedSchedule.enableTermsAndConditions ?
                api.termsAndConditions(company).get(selectedSchedule.termsAndConditionsTemplateId) : {data: undefined},
            api.encryption().getPublicKey(),
        ])) as [{ data: CustomerFieldsLabels }, { data: TermsAndConditions | undefined }, { data: string }];
        this.pubkey = pubkey;
        this.setState({
            fieldLabels,
            termsAndConditions
        });
    }

    captchaCb(token: string | null) {
        this.setState({captchaToken: token || ''});
    }

    setShowTyc = (show: boolean) => {
        this.setState({showTyc: show})
    }

    acceptTyc = () => {
        this.setState({termsAccepted: true});
        this.setShowTyc(false);
    }

    changedTycAccepted = (ev: ChangeEvent<HTMLInputElement>) => {
        this.setState({termsAccepted: ev.target.checked})
    }

    render() {
        const {selectedSchedule, selectedBranch, dateTime, company} = this.context.state;

        if (!company || !selectedSchedule || !selectedBranch) return <></>;

        const {termsAndConditions, fieldLabels, captchaToken, showTyc, termsAccepted} = this.state;
        const {t} = this.context;
        const hasTyC = !!termsAndConditions?.termAndCondition;

        const fields = getTranslations(this.fieldsToShow.reduce((obj: CustomerFields, f) => {
            obj[f] = true;
            return obj;
        }, {}), fieldLabels, t);

        return (
            <AppConsumer>
                {({state, t}) => (
                    <>
                        <div className="confirm-section form-card__content">
                            <div className="confirm-section__appointmentInfo">
                                <h3><i className="bi bi-calendar-check"></i>{t("ConfirmView.YOUR_APPOINTMENT")} </h3>
                                <div className="confirm-section__appointmentInfo-content">
                                    <div className="confirm-section__appointmentInfo-content_icon">
                                        <i className="bi bi-journal-text confirm-section__appointmentInfo--icon"></i>
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_data">
                                        {selectedSchedule.name}
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_edit">
                                        <button className="confirm-section__appointmentInfo-edit"
                                                onClick={() => this.editData("schedule")}>
                                            <i className="bi bi-pencil"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="confirm-section__appointmentInfo-content">
                                    <div className="confirm-section__appointmentInfo-content_icon">
                                        <i className="bi bi-calendar3 confirm-section__appointmentInfo--icon"></i>
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_data">
                                        {dayjs(dateTime).format("L") /* Locale date */}
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_edit">
                                        <button className="confirm-section__appointmentInfo-edit"
                                                onClick={() => this.editData("dateOrTime")}>
                                            <i className="bi bi-pencil"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="confirm-section__appointmentInfo-content">
                                    <div className="confirm-section__appointmentInfo-content_icon">
                                        <i className="bi bi-clock confirm-section__appointmentInfo--icon"></i>
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_data">
                                        {dayjs(dateTime).format("LT") /* Locale time */}
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_edit">
                                        <button className="confirm-section__appointmentInfo-edit"
                                                onClick={() => this.editData("dateOrTime")}>
                                            <i className="bi bi-pencil"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="confirm-section__appointmentInfo-content">
                                    <div className="confirm-section__appointmentInfo-content_icon">
                                        <i className="bi bi-geo-alt-fill confirm-section__appointmentInfo--icon"></i>
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_data">
                                        {selectedBranch.direccion ? selectedBranch.name + " - " + selectedBranch.direccion : selectedBranch.name}
                                    </div>
                                    <div className="confirm-section__appointmentInfo-content_edit">
                                        <button className="confirm-section__appointmentInfo-edit"
                                                onClick={() => this.editData("branch")}>
                                            <i className="bi bi-pencil"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="confirm-section__customerInfo">
                                <h3><i className="bi bi-person-fill"></i>{t("ConfirmView.YOUR_DATA")} </h3>
                                {
                                    fields.filter(f => !!state?.customer?.[f.key]).map(f => {
                                        return (
                                            <p key={f.key}><span>{f.showName}:</span> {state?.customer?.[f.key]} </p>
                                        )
                                    })
                                }
                            </div>
                            {
                                selectedSchedule.enableTermsAndConditions &&
                                <div className='confirm-section__tyc-container'>
                                    <input type='checkbox' checked={termsAccepted} onChange={this.changedTycAccepted}/>
                                    <button className="link-button" style={{
                                        textDecoration: hasTyC ? 'underline' : 'none',
                                        cursor: hasTyC ? 'pointer' : 'unset'
                                    }} onClick={this.setShowTyc.bind(this, hasTyC)}>
                                        {termsAndConditions?.label || termsAndConditions?.title}
                                    </button>
                                </div>
                            }
                            {
                                selectedSchedule.captchaValidation &&
                                <div className="confirm-section__captcha">
                                    <ReCAPTCHA
                                        sitekey={this.sitekey}
                                        size="compact"
                                        onChange={token => this.captchaCb(token)}
                                        ref={(e: ReCAPTCHA) => this.recaptchaInstance = e}
                                    />
                                </div>
                            }
                        </div>
                        {
                            showTyc && <ModalDialog
                                onConfirm={this.acceptTyc}
                                onCancel={this.setShowTyc.bind(this, false)}
                                type={EModalTypes.PERSUADE}
                                description={termsAndConditions?.termAndCondition || ''}
                                title={termsAndConditions?.label || ''}
                                useMarkdown
                                primaryLabel={t("ConfirmView.ACCEPT")}
                                secondaryLabel={t("ConfirmView.CANCEL")}
                            />
                        }

                        <div className="list-selection__buttons">
                            <TrackedButton
                                id="prev_confirm"
                                className="list-selection__button list-selection__button-green form-card__button form-card__button--previous"
                                onClick={this.goBack}> {t("ConfirmView.PREVIOUS")}
                            </TrackedButton>
                            <TrackedButton
                                id="next_confirm"
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--next"
                                disabled={(selectedSchedule.captchaValidation && !captchaToken) || (selectedSchedule.enableTermsAndConditions && !termsAccepted)}
                                onClick={_ => this.next()}>{t("ConfirmView.NEXT")}
                            </TrackedButton>
                        </div>
                    </>
                )}
            </AppConsumer>
        )
    }

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>;
}

export default withTracker(ConfirmView);