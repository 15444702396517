import React from "react";
import { AppConsumer, AppContext } from "../components/AppContext";
import Calendar, { CalendarTileProperties, ViewCallbackProperties } from "react-calendar";
import { IGenericProps } from "../interfaces/generics";
import api from "../requests/api";
import dayjs from "dayjs";
import { Slot } from "../interfaces/models";
import { getNextRoute } from "../util/RouteHelper";
import { toast } from "react-toastify";
import * as Analytics from '../util/Analytics';
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import { setAutodetectedLocale } from "../util/DayjsLocaleResolver";

setAutodetectedLocale()

type hour = { show: string, slot: Slot };

interface ICalendarView {
    date?: Date,
    hours?: hour[],
    showHours: boolean,
    selectedHour?: hour,
    availability: Slot[],
    defaultActiveStartDate?: Date,
    dayWithSlots: { [key: number]: boolean },
    appoMaxTolerance: number
}

class CalendarView extends React.Component<IGenericProps, ICalendarView> {
    constructor(props: IGenericProps) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.next = this.next.bind(this);
        this.selectHour = this.selectHour.bind(this);
        this.dateDisabled = this.dateDisabled.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onActiveStartDateChange = this.onActiveStartDateChange.bind(this);
        this.state = {
            date: undefined,
            hours: [],
            showHours: false,
            selectedHour: undefined,
            availability: [],
            defaultActiveStartDate: undefined,
            dayWithSlots: {},
            appoMaxTolerance: 0
        };
    }

    async onChange(date: Date) {
        if(this.state.date && dayjs(date).isSame(this.state.date, 'day'))
            return;

        await this.context.actions.selectDate(undefined);
        this.setState({
            date: date,
            showHours: true,
            hours: this.computeHours(date),
            selectedHour: undefined
        });
        Analytics.event("day_calendar");
    };

    async onActiveStartDateChange({view, activeStartDate}: ViewCallbackProperties) {
        await new Promise<void>((res) => this.setState({dayWithSlots: {}}, res))
        if(view !== "month") return;
        await this.getAvailability(activeStartDate);
    }

    selectHour(selected: hour) {
        this.setState({ selectedHour: selected });
        Analytics.event("hour_calendar");
        this.context.actions.selectDate(new Date(selected.slot.zonedStartDate));
    }

    async goBack() {
        await this.context.actions.selectDate(undefined);
        this.props.history.goBack();
    }

    next() {
        this.props.history.push(getNextRoute(this.context));
    }

    async getAvailability(date: Date) {
        const { selectedSchedule, selectedBranch, selectedScheduleName, company } = this.context.state;
        const { t } = this.context;
        if (!company || company.name !== this.props.match.params.companyName) {
            console.error("No company was selected in CalendarView");
            if (!this.props.match.params.companyName)
                toast.error(t("CalendarView.TOAST_NO_COMPANY_SELECTED"));
            this.props.history.push(`/company/${this.props.match.params.companyName || ''}`);
            return;
        }
        if (!selectedScheduleName || (selectedBranch && !selectedSchedule)) {
            console.error("No schedule was selected in CalendarView");
            toast.error(t("CalendarView.TOAST_NO_SCHEDULE_SELECTED"));
            this.props.history.push(`/company/${this.props.match.params.companyName || ''}`);
            return;
        }
        const dateStr = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZZ');
        let res;
        if (selectedBranch)
            res = (await api.availability().getInMonth(selectedSchedule.id, selectedBranch.id, dateStr)).data as { slots: Slot[] };
        else
            res = (await api.availability().getInMonthForAllBranches(company.name, selectedScheduleName, dateStr)).data as { slots: Slot[] };

        const availability = res.slots.filter(slot => slot.availability > 0);
        this.setState({ availability })
        this.computeDaysWithSlots(date, availability);
        return availability;
    }

    computeDaysWithSlots(initialDate: Date, availability: Slot[]){
        const dayWithSlots: { [key: number]: boolean } = {};
        availability.forEach((slot: Slot) => {
            const slotDate = dayjs(slot.zonedStartDate);
            if(slotDate.isSame(initialDate, 'month'))
                dayWithSlots[slotDate.date()] = true;
        });
        this.setState({dayWithSlots});
    }

    dateDisabled({ date, view, activeStartDate }: CalendarTileProperties): boolean {
        if(this.state.date && dayjs(date).isSame(this.state.date, 'day')) return false;
        if (view !== "month") return false;
        if(!dayjs(date).isSame(activeStartDate, 'month')) return true;

        const tileDate = dayjs(date);
        return !this.state.dayWithSlots[tileDate.date()];
    }

    computeHours(date: Date): hour[] {
        const { selectedSchedule } = this.context.state;
        const month = date.getMonth(), day = date.getDate();
        const availabilityInDate = this.state.availability.filter(slot => {
            const dayjsStartDate = dayjs(slot.startDate);
            return dayjsStartDate.date() === day &&
                dayjsStartDate.month() === month;
        });
        const hours = availabilityInDate
            .filter(slot => {
                return !selectedSchedule.appoMaxTolerance || !dayjs().isSame(slot.zonedStartDate, "day") ||
                    dayjs().add(selectedSchedule.appoMaxTolerance, "minutes").isBefore(slot.zonedStartDate);
            })
            .map(slot => {
                return {
                    show: dayjs(slot.startDate).format("LT"), // Locale Time format
                    slot
                } as hour;
            });
        return hours;
    }

    async componentDidMount() {
        let dateToSearch = new Date();
        const tries = 7;
        for (let i = 0; i < tries; ++i) {
            const availability = await this.getAvailability(dateToSearch);
            if(!availability){
                // There was an error, will redirect
                return;
            } else if(availability.length > 0){
                // Some availability found
                dateToSearch = new Date(availability[0].startDate);
                this.setState({defaultActiveStartDate: dateToSearch})
                return;
            }
            // Did not find anything this month, trying next month
            dateToSearch = dayjs(dateToSearch).add(1, "month").toDate()
        }
        this.context.actions.selectDate(undefined);
    }

    componentDidUpdate() {
        const ctxCompany = this.context.state.company;
        if (ctxCompany?.name !== this.props.match.params.companyName) {
            console.warn("Company changed, redirecting");
            this.props.history.push(`/company/${this.props.match.params.companyName || ''}`);
            return;
        }
    }

    render() {
        const { hours, selectedHour, defaultActiveStartDate} = this.state;

        return (
            <AppConsumer>
                {({ state, t }) => (
                    <>
                        <div className="list-selection list-selection__calendar form-card__content">
                            <div className="list-selection_calendar">
                                <Calendar onChange={this.onChange}
                                    tileDisabled={this.dateDisabled}
                                    onActiveStartDateChange={this.onActiveStartDateChange}
                                    defaultActiveStartDate={defaultActiveStartDate}
                                />
                            </div>
                            {
                                this.state.showHours &&
                                <div className="list-selection__hours">
                                    <div className="list-selection__hours-list">
                                        {hours?.map((h: hour, index: number) => {
                                            return (
                                                <li key={index} className={
                                                    "list-selection__hours-list-selecthour" +
                                                    (h === selectedHour ? " list-selection__hours-list-selecthour-active" : "")} onClick={this.selectHour.bind(this, h)}>
                                                    {h.show}
                                                </li>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="list-selection__buttons">
                            <button
                                id="backToScheduleFirst"
                                className="list-selection__button list-selection__button-green"
                                onClick={this.goBack} >

                                {t("CalendarView.PREVIOUS")}
                            </button>
                            <TrackedButton
                                id="next_calendar"
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--next" onClick={this.next} disabled={!state.dateTime}
                                extraData={{date: selectedHour?.slot.startDate, hour: selectedHour?.show}}>
                                {t("CalendarView.NEXT")}
                            </TrackedButton>
                        </div>
                    </>
                )}
            </AppConsumer>
        );
    }
}

CalendarView.contextType = AppContext;

export default withTracker(CalendarView);